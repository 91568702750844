import styled from 'styled-components/macro';

const FullWidthContainer = styled.div`
  width: 100vw;
  position: relative;
  margin-left: -50vw;
  left: 50%;
`;

export default FullWidthContainer;
