import React from "react";
import { css } from "styled-components/macro";
import media from "../styleUtils/media";

const encode = (data) =>
  Object.entries(data)
    .map(
      ([key, value]) =>
        encodeURIComponent(key) + "=" + encodeURIComponent(value)
    )
    .join("&");

const ContactFrom = () => {
  const [status, setStatus] = React.useState("idle");

  const nameRef = React.useRef();
  const emailRef = React.useRef();
  const messageRef = React.useRef();
  const consentRef = React.useRef();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("loading");
    const formData = {
      name: nameRef.current?.value,
      email: emailRef.current?.value,
      message: messageRef.current?.value,
      consent: consentRef.current?.value,
    };

    try {
      await fetch("/?no-cache=1", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "kontakt", ...formData }),
      }).then((response) => {
        if (!response.ok) {
          console.error(response);
          throw new Error("Network response was not ok");
        }
        return response;
      });

      setStatus("success");
    } catch (e) {
      console.error(e);
      setStatus("error");
    }

    return false;
  };

  if (status === "loading") {
    return <div>Trwa wysyłanie</div>;
  }
  if (status === "success") {
    return (
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 2rem;
        `}
      >
        <div>Wysłano wiadomość</div>
        <button
          className="button"
          onClick={() => {
            setStatus("idle");
          }}
        >
          Wyślij kolejną wiadomość
        </button>
      </div>
    );
  }

  return (
    <div>
      <form
        data-netlify="true"
        name="kontakt"
        method="POST"
        css={css`
          display: flex;
          flex-direction: column;
          gap: 1rem;
          padding: 0 0.5rem;

          ${media.tablet`
          ${css`
            padding: 0 1rem;
          `}
        `};
        `}
        onSubmit={handleSubmit}
      >
        <div className="form-row">
          <label
            id="name-label"
            htmlFor="name"
            css={css`
              max-width: 10rem;
            `}
          >
            Imię
          </label>
          <input
            ref={nameRef}
            id="name"
            type="text"
            name="name"
            aria-labelledby="name-label"
            required
          />
        </div>
        <div className="form-row">
          <label id="email-label" htmlFor="email">
            Email
          </label>
          <input
            ref={emailRef}
            id="email"
            type="email"
            name="email"
            aria-labelledby="email-label"
            required
          />
        </div>
        <div className="form-row">
          <label id="message-label" htmlFor="message">
            Wiadomość
          </label>
          <textarea
            ref={messageRef}
            id="message"
            name="message"
            rows={5}
            aria-labelledby="message-label"
            defaultValue={""}
            required
          />
        </div>
        <div className="form-row">
          <div className="form-checkbox">
            <input
              id="consent"
              type="checkbox"
              name="consent"
              aria-labelledby="consent-label"
              required
              ref={consentRef}
            />
            <label
              htmlFor="consent"
              id="consent-label"
              css={css`
                font-weight: normal;
              `}
            >
              Wyrażam zgodę na przetwarzanie danych osobowych zgodnie z ustawą o
              ochronie danych osobowych w celu wysłania zapytania przez
              formularz. Podanie danych jest dobrowolne, ale niezbędne do
              udzielenia odpowiedzi na zapytanie. Zostałem poinformowany, że
              przysługuje mi prawo dostępu do danych, ich zmiany i usunięcia.
              Administratorem danych osobowych jest Fundacja Dobra Przestrzeń.
            </label>
          </div>
        </div>
        <div className="form-row form-submit">
          <button type="submit" className="button">
            Wyślij wiadomość
          </button>
          {status === "error" ? (
            <div
              css={css`
                margin-top: 1rem;
                color: red;
              `}
            >
              Coś poszło nie tak. Spróbuj jeszcze raz.
            </div>
          ) : null}
        </div>
      </form>
    </div>
  );
};

export default ContactFrom;
