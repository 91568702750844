import React from "react";
import _ from "lodash";
import { Layout } from "../components/index";
import { htmlToReact, safePrefix } from "../utils";
import ContactMapFull from "../components/ContactMapFull";
import FeatureLarge from "../components/FeatureLarge";
import FullWidthContainer from "../components/FullWidthContainer";
import styled, { css } from "styled-components/macro";
import Helmet from "react-helmet";
import ContactFrom from "../components/ContactFrom";
import { BooksyWidget } from "../components/BooksyWidget";
import { RESERVE_ONLINE_ENABLED } from "../constants/config";

export default class Contact extends React.Component {
  render() {
    return (
      <Layout {...this.props}>
        <Helmet>
          <title>
            {_.get(this.props, "pageContext.frontmatter.title") &&
              _.get(this.props, "pageContext.frontmatter.title") + " - "}
            {_.get(this.props, "pageContext.site.siteMetadata.title")}
          </title>
        </Helmet>
        <div className="outer">
          <div className="inner-medium">
            <article className="post page post-full">
              <header className="post-header">
                <h1 className="post-title">
                  {_.get(this.props, "pageContext.frontmatter.title")}
                </h1>
              </header>
              {_.get(this.props, "pageContext.frontmatter.subtitle") && (
                <div className="post-subtitle">
                  {htmlToReact(
                    _.get(this.props, "pageContext.frontmatter.subtitle")
                  )}
                </div>
              )}
              {_.get(this.props, "pageContext.frontmatter.img_path") && (
                <div className="post-thumbnail">
                  <ContactImage
                    src={safePrefix(
                      _.get(this.props, "pageContext.frontmatter.img_path")
                    )}
                    alt={_.get(this.props, "pageContext.frontmatter.title")}
                  />
                </div>
              )}

              <div
                className="post-content post-content--center"
                css={css`
                  margin-bottom: 1rem;
                `}
              >
                {htmlToReact(
                  _.get(this.props, "pageContext.frontmatter.description")
                )}
              </div>

              <ReserveOnline />

              <FullWidthContainer>
                <FeatureLarge>
                  <FeatureLarge.Content order="normal">
                    <FeatureLarge.Body>
                      {htmlToReact(_.get(this.props, "pageContext.html"))}
                    </FeatureLarge.Body>
                  </FeatureLarge.Content>

                  <ContactFrom />
                </FeatureLarge>

                <FeatureLarge
                  css={css`
                    margin-bottom: 0;
                  `}
                >
                  <FeatureLarge.Content order="normal">
                    <FeatureLarge.Body>
                      <h3>Centrum psychologiczne</h3>
                      <p>Psychoterapia, joga, warsztaty, szkolenia</p>
                      <strong>Adres:</strong>
                      <p>
                        ul. Drzymały 18/24 <br />
                        Warszawa, Ursus <br />
                        02-495
                      </p>
                    </FeatureLarge.Body>
                  </FeatureLarge.Content>
                  <ContactMapFull
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d21535.395632521202!2d20.88825869233406!3d52.20062165334507!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471935530fc963c5%3A0xfbdd07908a12c7ea!2sDobra+Przestrze%C5%84+%E2%80%94+psychoterapia%2C+ruch+i+rozw%C3%B3j!5e0!3m2!1sen!2spl!4v1562419625675!5m2!1sen!2spl"
                    width="100%"
                    height="450"
                    frameBorder="0"
                    style={{ border: 0 }}
                    allowFullScreen
                  />
                </FeatureLarge>
                <ContactImagesContainer>
                  <ContactImage
                    alt="Psycholog Przychoterapeuta Warszawa Ursus. Dobra Przestrzeń. Warszawa ul. Drzymały 18/24"
                    src="/images/kontakt-drzymaly-1.jpg"
                    loading="lazy"
                  />
                  <ContactImage
                    alt="Psycholog Przychoterapeuta Joga Warszawa Ursus. Dobra Przestrzeń. Warszawa ul. Drzymały 18/24"
                    src="/images/kontakt-drzymaly-2.jpg"
                    loading="lazy"
                  />
                </ContactImagesContainer>

                <FeatureLarge
                  css={css`
                    margin-bottom: 0;
                  `}
                >
                  <FeatureLarge.Content order="normal">
                    <FeatureLarge.Body>
                      <h3>Niepubliczna poradnia psychologiczno-pedagogiczna</h3>
                      <p>
                        Dla dzieci i młodzieży: <br /> diagnostyka,
                        psychoterapia, warsztaty
                      </p>
                      <strong>Adres:</strong>
                      <p>
                        Rakietników 10 <br />
                        Warszawa, Ursus <br />
                        02-495
                      </p>
                    </FeatureLarge.Body>
                  </FeatureLarge.Content>

                  <ContactMapFull
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9782.29573898733!2d20.8916369!3d52.1966169!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfbc28a67c47da5b6!2sDobra%20Przestrze%C5%84%20%E2%80%94%20Niepubliczna%20poradnia%20psychologiczno-pedagogiczna!5e0!3m2!1sen!2spl!4v1628452586679!5m2!1sen!2spl"
                    width="100%"
                    height="450"
                    frameBorder="0"
                    style={{ border: 0 }}
                    allowFullScreen
                  />
                </FeatureLarge>

                <ContactImagesContainer>
                  <ContactImage
                    alt="Dla dzieci i młodzieży: diagnostyka, psychoterapia, warsztaty. Niepubliczna poradnia psychologiczno-pedagogiczna. Dobra Przestrzeń. ul Rakietników 10"
                    src="/images/kontakt-rakietnikow.jpg"
                    loading="lazy"
                  />
                  <ContactImage
                    alt="Dla dzieci i młodzieży: diagnostyka, psychoterapia, warsztaty. Niepubliczna poradnia psychologiczno-pedagogiczna. Dobra Przestrzeń. ul Rakietników 10"
                    src="/images/kontakt-rakietnikow-2.jpg"
                    loading="lazy"
                  />
                </ContactImagesContainer>
              </FullWidthContainer>
            </article>
          </div>
        </div>
      </Layout>
    );
  }
}

const ContactImagesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  max-width: 1140px;
  gap: 1rem;
  margin-left: auto;
  margin-right: auto;
  align-items: stretch;
  justify-items: stretch;
  margin-bottom: 6rem;
  padding: 0 1rem;
`;

const ContactImage = styled.img`
  border-radius: 0.5rem;
`;

const ReserveOnline = () => {
  if (!RESERVE_ONLINE_ENABLED) return null;
  return (
    <FeatureLarge>
      <FeatureLarge.Content order="normal">
        <FeatureLarge.Body>
          <h3
            css={css`
              font-size: 1.33rem;
            `}
          >
            Umów online
          </h3>
        </FeatureLarge.Body>
      </FeatureLarge.Content>

      <BooksyWidget />
    </FeatureLarge>
  );
};
